.Tabs__in {
  display: flex;
  align-items: stretch;
}

.Tabs--withGaps {
  justify-content: flex-start;
}

/* TODO v5.0.0 Удалить mode="segmented" */
.Tabs--ios.Tabs--segmented {
  padding: 4px 12px 8px;
}

/**
 * CMP:
 * PanelHeader
 */
.PanelHeader .Tabs {
  overflow: hidden;
  font-family: var(--font-common);
}

.PanelHeader .Tabs::after {
  display: block;
  width: 1000px;
  content: "";
}

/**
 * CMP:
 * Group
 */
.Group--plain > .Tabs--default:first-child {
  margin-top: -8px;
}

.Group--card > .Tabs--default:first-child {
  margin-top: -4px;
}

/**
 * CMP:
 * FixedLayout
 */
/* Нужно для закрашивания прозрачной области нижнего инсета на iphone x */
.FixedLayout--ios.FixedLayout--bottom .Tabs::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: var(--background_content, var(--vkui--color_background_content));
}

.FixedLayout--ios.FixedLayout--bottom .Tabs__in,
.FixedLayout--ios.FixedLayout--bottom .Separator {
  position: relative;
  z-index: 2;
}
