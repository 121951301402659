.Group {
  color: var(--text_primary, var(--vkui--color_text_primary));
  padding-top: 8px;
  padding-bottom: 8px;
}

.Group--card.Group--padding-s {
  padding: 4px;
}

.Group--card.Group--padding-m {
  padding: 8px;
}

/* TODO v5.0.0 Новая адаптивность */
.Group--sizeX-compact {
  padding-left: 0;
  padding-right: 0;
}

.Group--card {
  background: var(--background_content, var(--vkui--color_background_content));
  border-radius: var(--vkui--size_border_radius_paper--regular);
  position: relative;
}

.Group--card::after {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  box-shadow: inset 0 0 0 var(--thin-border)
    var(--separator_common, var(--vkui--color_separator_primary));
}

.Group--plain + .Group__separator {
  padding-top: 8px;
  padding-bottom: 8px;
}

/* TODO v5.0.0 Новая адаптивность */
.Group--sizeX-regular.Group--plain + .Group__separator {
  padding: 8px;
}

.Group:last-of-type + .Group__separator {
  display: none;
}

.Group:last-of-type + .Group__separator--force,
/* TODO v5.0.0 Новая адаптивность */
.Group--sizeX-regular.Group--card:last-of-type + .Group__separator {
  display: block;
}

.Group__description {
  display: block;
  padding: 4px 16px 16px;
  color: var(--text_secondary, var(--vkui--color_text_secondary));
}

.Group .Group,
.Group .Group + .Group__separator {
  padding-left: 0;
  padding-right: 0;
}

.Group .Group:first-of-type {
  padding-top: 0;
}

.Group .Group:last-of-type {
  padding-bottom: 0;
}

/**
 * iOS
 */
.Group--ios .Group__description {
  padding: 4px 12px 16px;
}

/*
 * CMP:
 * PanelHeader
 */
.PanelHeader--vkcom ~ .Group--card:first-of-type,
.PanelHeader--vkcom + * .Group--card:first-of-type {
  position: relative;
  top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
