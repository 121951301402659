:root {
	--text_chrome: #5f6368;
}

body[scheme="amoled"],
body[scheme="space_gray"] {
	--text_chrome: var(--text_primary);
}

body[scheme="amoled"] {
	--accent: #71aaeb;
	--action_sheet_action_foreground: #ffffff;
	--action_sheet_separator: rgba(255, 255, 255, 0.12);
	--activity_indicator_tint: #5d5f61;
	--attach_picker_tab_active_background: #e1e3e6;
	--attach_picker_tab_active_icon: #19191a;
	--attach_picker_tab_active_text: #e1e3e6;
	--attach_picker_tab_inactive_background: #2c2d2e;
	--attach_picker_tab_inactive_icon: #e1e3e6;
	--attach_picker_tab_inactive_text: #76787a;
	--background_content: #000000;
	--background_highlighted: rgba(255, 255, 255, 0.08);
	--background_hover: rgba(255, 255, 255, 0.04);
	--background_keyboard: #2c2d2e;
	--background_light: #232324;
	--background_page: #000000;
	--background_suggestions: #2c2d2e;
	--background_text_highlighted: rgba(113, 170, 235, 0.24);
	--button_bot_shadow: #19191a;
	--button_commerce_background: #4bb34b;
	--button_commerce_foreground: #ffffff;
	--button_muted_background: #2c2d2e;
	--button_muted_foreground: #e1e3e6;
	--button_outline_border: #e1e3e6;
	--button_outline_foreground: #e1e3e6;
	--button_primary_background: #e1e3e6;
	--button_primary_foreground: #19191a;
	--button_secondary_background: #454647;
	--button_secondary_background_highlighted: rgba(69, 70, 71, 0.6);
	--button_secondary_destructive_background: #454647;
	--button_secondary_destructive_background_highlighted: rgba(
		69,
		70,
		71,
		0.6
	);
	--button_secondary_destructive_foreground: #ff5c5c;
	--button_secondary_destructive_foreground_highlighted: rgba(
		255,
		92,
		92,
		0.6
	);
	--button_secondary_foreground: #e1e3e6;
	--button_secondary_foreground_highlighted: rgba(225, 227, 230, 0.6);
	--button_tertiary_background: rgba(0, 0, 0, 0);
	--button_tertiary_foreground: #e1e3e6;
	--cell_button_foreground: #e1e3e6;
	--content_placeholder_icon: #aaaeb3;
	--content_placeholder_text: #aaaeb3;
	--content_tint_background: #131315;
	--content_tint_foreground: #909499;
	--content_warning_background: #857250;
	--control_background: #454647;
	--control_foreground: #e1e3e6;
	--control_tint: #19191a;
	--control_tint_muted: #909499;
	--counter_primary_background: #ffffff;
	--counter_primary_text: #19191a;
	--counter_prominent_background: #ff3347;
	--counter_prominent_text: #ffffff;
	--counter_secondary_background: #76787a;
	--counter_secondary_text: #19191a;
	--destructive: #ff5c5c;
	--dynamic_blue: #3f8ae0;
	--dynamic_gray: #a3adb8;
	--dynamic_green: #4bb34b;
	--dynamic_orange: #ffa000;
	--dynamic_purple: #937ff5;
	--dynamic_red: #ff3347;
	--dynamic_violet: #a393f5;
	--feed_recommended_friend_promo_background: #6d7885;
	--field_background: #232324;
	--field_border: rgba(255, 255, 255, 0.12);
	--field_error_background: #522e2e;
	--field_error_border: #ff5c5c;
	--field_text_placeholder: #76787a;
	--field_valid_border: #4bb34b;
	--float_button_background: #454647;
	--float_button_background_highlighted: #5d5f61;
	--float_button_border: rgba(0, 0, 0, 0.12);
	--float_button_foreground: #aaaeb3;
	--header_alternate_background: #2c2d2e;
	--header_alternate_tab_active_indicator: #e1e3e6;
	--header_alternate_tab_active_text: #e1e3e6;
	--header_alternate_tab_inactive_text: #76787a;
	--header_background: #19191a;
	--header_background_before_blur: #070708;
	--header_background_before_blur_alternate: #070708;
	--header_search_field_background: #363738;
	--header_search_field_tint: #aaaeb3;
	--header_tab_active_background: #5d5f61;
	--header_tab_active_indicator: #71aaeb;
	--header_tab_active_text: #e1e3e6;
	--header_tab_inactive_text: #76787a;
	--header_text: #e1e3e6;
	--header_text_alternate: #e1e3e6;
	--header_text_secondary: rgba(255, 255, 255, 0.6);
	--header_tint: #e1e3e6;
	--header_tint_alternate: #e1e3e6;
	--icon_alpha_placeholder: #e1e3e6;
	--icon_medium: #909499;
	--icon_medium_alpha: rgba(255, 255, 255, 0.48);
	--icon_name: #aaaeb3;
	--icon_outline_medium: #aaaeb3;
	--icon_outline_secondary: #909499;
	--icon_secondary: #76787a;
	--icon_secondary_alpha: rgba(255, 255, 255, 0.36);
	--icon_tertiary: #5d5f61;
	--icon_tertiary_alpha: rgba(255, 255, 255, 0.24);
	--im_attach_tint: #ffffff;
	--im_bubble_border_alternate: rgba(0, 0, 0, 0);
	--im_bubble_border_alternate_highlighted: rgba(0, 0, 0, 0);
	--im_bubble_button_background: rgba(255, 255, 255, 0.16);
	--im_bubble_button_background_highlighted: rgba(255, 255, 255, 0.24);
	--im_bubble_button_foreground: #e1e3e6;
	--im_bubble_button_outgoing_background: #5d5f61;
	--im_bubble_gift_background: #857250;
	--im_bubble_gift_background_highlighted: #ab9871;
	--im_bubble_gift_text: #ffffff;
	--im_bubble_gift_text_secondary: #e3d3ac;
	--im_bubble_incoming: #2c2d2e;
	--im_bubble_incoming_alternate: #2c2d2e;
	--im_bubble_incoming_alternate_highlighted: #5d5f61;
	--im_bubble_incoming_expiring: #262b4d;
	--im_bubble_incoming_expiring_highlighted: #404980;
	--im_bubble_incoming_highlighted: #5d5f61;
	--im_bubble_outgoing: #454647;
	--im_bubble_outgoing_alternate: #454647;
	--im_bubble_outgoing_alternate_highlighted: #76787a;
	--im_bubble_outgoing_expiring: #404980;
	--im_bubble_outgoing_expiring_highlighted: #5965b3;
	--im_bubble_outgoing_highlighted: #5d5f61;
	--im_bubble_wallpaper_button_background: #454647;
	--im_bubble_wallpaper_button_background_highlighted: #76787a;
	--im_bubble_wallpaper_button_foreground: #e1e3e6;
	--im_bubble_wallpaper_incoming: #2c2d2e;
	--im_bubble_wallpaper_incoming_highlighted: #5d5f61;
	--im_bubble_wallpaper_outgoing: #454647;
	--im_bubble_wallpaper_outgoing_highlighted: #5d5f61;
	--im_forward_line_tint: #ffffff;
	--im_reply_separator: #ffffff;
	--im_service_message_text: #76787a;
	--im_text_name: #71aaeb;
	--im_toolbar_separator: #aaaeb3;
	--im_toolbar_voice_msg_background: #454647;
	--image_border: rgba(255, 255, 255, 0.08);
	--input_background: #2c2d2e;
	--input_border: #1a1a1b;
	--landing_background: #19191a;
	--landing_field_background: #232324;
	--landing_field_border: rgba(0, 0, 0, 0);
	--landing_field_placeholder: #aaaeb3;
	--landing_login_button_background: #e1e3e6;
	--landing_login_button_foreground: #19191a;
	--landing_primary_button_background: #e1e3e6;
	--landing_primary_button_foreground: #19191a;
	--landing_secondary_button_background: rgba(255, 255, 255, 0.15);
	--landing_secondary_button_foreground: #ffffff;
	--landing_snippet_border: rgba(255, 255, 255, 0.15);
	--landing_tertiary_button_background: rgba(0, 0, 0, 0);
	--landing_tertiary_button_foreground: #ffffff;
	--landing_text_primary: #e1e3e6;
	--landing_text_secondary: #76787a;
	--landing_text_title: #ffffff;
	--like_text_tint: #ff5c5c;
	--link_alternate: #ffffff;
	--loader_background: #19191a;
	--loader_tint: #76787a;
	--loader_track_fill: #454647;
	--loader_track_value_fill: #ffffff;
	--media_overlay_button_background: #ffffff;
	--media_overlay_button_foreground: #2c2d2e;
	--modal_card_background: #2c2d2e;
	--modal_card_border: rgba(255, 255, 255, 0.12);
	--modal_card_header_close: #ffffff;
	--music_playback_icon: #ffffff;
	--overlay_status_background: #19191a;
	--overlay_status_foreground: #e1e3e6;
	--overlay_status_icon: #ebedf0;
	--panel_tab_active_background: #454647;
	--panel_tab_active_text: #e1e3e6;
	--panel_tab_inactive_text: #76787a;
	--placeholder_icon_background: rgba(255, 255, 255, 0.08);
	--placeholder_icon_background_opaque: #232324;
	--placeholder_icon_foreground_primary: #909499;
	--placeholder_icon_foreground_secondary: #5d5f61;
	--placeholder_icon_tint: #ffffff;
	--poll_option_background: #ffffff;
	--search_bar_background: #19191a;
	--search_bar_field_background: #363738;
	--search_bar_field_tint: #aaaeb3;
	--search_bar_segmented_control_tint: #909499;
	--segmented_control_bar_background: #19191a;
	--segmented_control_tint: #76787a;
	--selection_off_icon: #5d5f61;
	--separator_alpha: rgba(255, 255, 255, 0.12);
	--separator_alternate: #232324;
	--separator_common: #363738;
	--skeleton_shimmer_from: #19191a;
	--skeleton_shimmer_to: #19191a;
	--snippet_background: #232324;
	--snippet_border: rgba(255, 255, 255, 0.15);
	--snippet_icon_tertiary: #5d5f61;
	--splashscreen_icon: #2c2d2e;
	--statusbar_alternate_legacy_background: rgba(0, 0, 0, 0);
	--stories_create_button_background_from: #363738;
	--stories_create_button_background_to: #363738;
	--stories_create_button_foreground: #c4c8cc;
	--stories_create_button_icon_background: #454647;
	--stories_skeleton_loader_background: #454647;
	--switch_ios_off_border: #2c2d2e;
	--tabbar_active_icon: #ffffff;
	--tabbar_background: #2c2d2e;
	--tabbar_inactive_icon: #76787a;
	--tabbar_tablet_active_icon: #71aaeb;
	--tabbar_tablet_background: #232324;
	--tabbar_tablet_inactive_icon: #76787a;
	--tabbar_tablet_text_primary: #e1e3e6;
	--tabbar_tablet_text_secondary: #76787a;
	--text_action_counter: #aaaeb3;
	--text_link: #71aaeb;
	--text_link_highlighted_background: #71aaeb;
	--text_muted: #c4c8cc;
	--text_name: #e1e3e6;
	--text_placeholder: #aaaeb3;
	--text_primary: #e1e3e6;
	--text_secondary: #76787a;
	--text_subhead: #909499;
	--text_tertiary: #5d5f61;
	--toolbar_attach_background_from: #76787a;
	--toolbar_attach_background_to: #5d5f61;
	--vk_connect_button_primary_background: #e1e3e6;
	--vk_connect_button_primary_foreground: #19191a;
	--vk_connect_button_secondary_background: #454647;
	--vk_connect_button_secondary_foreground: #e1e3e6;
	--writebar_icon: #e1e3e6;
}

body {
	background-color: var(--background_content);
	--vklScroll: #f1f1f1;
	--vklScrollThumb: #c1c1c1;
}
body[scheme="space_gray"] {
	--vklScroll: #19191a;
	--vklScrollThumb: #2d2d2d;
	--vkn_background: rgb(10 10 10);
	--vkn_background_select: rgb(31 31 31);
}
body[scheme="amoled"] {
	--vklScroll: #000;
	--vklScrollThumb: #1f1f1f;
	--vkn_background: rgba(0, 0, 0, 1);
	--vkn_background_select: rgba(23, 23, 23, 1);
}

.vknuiGrid {
	display: grid;
	grid-template-columns: 50% 50%;
}

.vknuiGrid > div {
	margin: 10px;
	background-color: var(--background_hover);
	box-shadow: 0 0 7px rgb(0 0 0 / 22%);
	border-radius: 10px;
}

.vkuiTabbarItem--selected {
	color: var(--accent) !important;
}
.subheader:first-child {
	margin-top: 0;
}
.subheader,
h4.subheader {
	margin: 18px 0 12px;
	padding: 0;
	border-bottom: none;
	font-weight: 500;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: auto;
	font-size: inherit;
}
.Card__flex {
	width: 630px !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

ol.installGuide li {
	padding: 8px 0;
}

ol.deleteGuide li {
	padding: 4px 0;
}

.faqHeader .vkuiHeader__in.Header__in {
	padding-left: 2px;
}

.faqHeader .vkuiHeader__main.Header__main {
	margin-top: 0;
}

.faqHeader .vkuiHeader__content-base.Header__content-base {
	white-space: inherit;
}

.faqText {
	padding-left: 10px;
}

.vkuiCard .imgCenter {
	display: flex;
	justify-content: center;
	padding: 5px 16px 3px 0px;
}

.galleryImage {
	max-width: 100%;
	max-height: 100%;
	position: relative;
	object-fit: contain;
	display: block;
}

.tabbar_fix {
	width: 100%;
	height: 48px;
}

.each-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

.panelCenter .vkuiPanel__in.Panel__in {
	display: flex;
	justify-content: center;
	align-items: center;
}

.copyBtn {
	width: 16px;
	height: 16px;
	position: absolute;
	top: 10px;
	right: 0;
	opacity: 0;
	transition: 0.25s;
}

.copyBtn:hover {
	opacity: 1;
	cursor: pointer;
}

.vkuiCard {
	padding-right: 12px;
}

.vkuiPanelHeader--ios.vkuiPanelHeader--no-right:not(.PanelHeader--no-left)
	.vkuiPanelHeader__content {
	margin-left: 4px;
}

.mbf .vkuiHeader__main {
	margin-bottom: 0 !important;
}

html.iframe .vkuiSplitCol {
	margin: 0;
}

html.iframe .vkuiPanelHeaderButton {
	border-radius: 8px;
}

.vkuiSimpleCell__description {
	white-space: break-spaces;
}
.vkuiCard__in .vkuiHeader__content-in {
	white-space: break-spaces !important;
}

body {
	overflow: overlay;
	scrollbar-color: var(--vkn_background_select) var(--vkn_background);
}

::-webkit-scrollbar {
	background-color: var(--vklScroll);
	width: 12px;
}

::-webkit-scrollbar-thumb {
	background-color: var(--vklScrollThumb);
	border-radius: 9em;
}

html.iframe ::-webkit-scrollbar {
	width: 7px;
}

.vkuiCardGrid.fixPadding > .vkuiCard {
	padding: 0;
}

.VKNBugs_wrap {
	margin-top: 10px;
}

.VKNBugs_wrap .vkuiSimpleCell {
	border-radius: 10px;
}

.VKNBugs_report_wrap .vkuiCaption,
.VKNBugs_report_wrap .vkuiCaption > div {
	font-size: 15px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.VKNBugs_card_wrap > .VKNBugs_card {
	margin-right: 5px;
	margin-bottom: 3px;
	margin-top: 4px;
}

.VKNBugs_card_wrap > .VKNBugs_card:last-child {
	margin-right: 0;
}

.VKNBugs_card {
	background-color: var(--modal_card_background);
	color: var(--text_secondary);
	padding: 2px 8px;
	border-radius: 10px;
	display: inline-block;
	font-size: 13px;
}

.VKNBugs_details {
	float: left;
}

.VKNBugs_status {
	float: right;
}

.VKNBugs_info {
	margin-top: 5px;
}

.VKNBugs_report_group > .vkuiGroup__inner {
	padding: 0;
	margin: 0;
}

.VKNBugs_file_close {
	position: absolute;
	bottom: 17px;
	left: 17px;
	color: var(--icon_secondary_alpha);
	cursor: pointer;
}

.VKNBugs_main_card {
	padding-right: 0px !important;
	margin-right: 0px !important;
	width: 100% !important;
	box-shadow: 1px 0px 20px 6px rgb(0 0 0 / 20%) !important;
}

.hideBottomRadius > .vkuiGroup__inner {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.VKNBugs_systemMsg_wrap > div {
	margin-top: 8px;
}

.VKNBugs_systemMsg_wrap > div:first-child {
	margin-top: 0;
}

body[scheme="bright_light"] .VKNBugs_main_card {
	box-shadow: 1px 0px 20px 5px rgb(0 0 0 / 10%) !important;
}

.VKNBugs_file_wrap {
	display: inline-flex;
	margin-right: 8px;
}

.VKNBugs_file_wrap > button {
	position: relative;
	height: 46px;
}

.emoji {
	width: 17px;
	height: 17px;
	vertical-align: -3px;
	display: inline-block;
}

.VKNBugs_openReport {
	font-weight: 500;
	cursor: pointer;
}

body[scheme="bright_light"] .VKNBugs_card {
	background-color: var(--background_page);
}

.VKNBugs_info_comment {
	margin-top: 30px;
}

.VKNBugs_dragAndDrop {
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: var(--separator_common);
	border-style: dashed;
	background-color: var(--background_content);
	color: var(--text_secondary);
	outline: none;
	-webkit-transition: border 0.24s ease-in-out;
	transition: border 0.24s ease-in-out;
	cursor: pointer;
}

.VKNBugs_dragAndDrop:hover .VKNBugs_dragAndDrop_text,
.VKNBugs_dragAndDrop:hover .VKNBugs_dragAndDrop_icon {
	color: var(--text_primary);
}

.VKNBugs_dragAndDrop._drop {
	border-color: var(--field_valid_border);
	color: var(--field_valid_border);
}

.VKNBugs_dragAndDrop._drop .VKNBugs_dragAndDrop_text {
	color: var(--text_secondary);
}

.VKNBugs_dragAndDrop_text,
.VKNBugs_dragAndDrop_icon {
	transition: color 0.24s ease-in-out;
}
